import https from "./https"

const partnerWorkerApi = {
  getPartnerWorkers (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-workers'

    return https.get(url, { params })
  },
  getPartnerWorker (organization, partnerWorkerId) {
    const url = 'admin/organizations/' + organization.id  + '/partner-workers/' + partnerWorkerId
    return https.get(url)
  },
  updatePartnerWorker (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-workers/' + params.id;
    return https.put(url, params)
  },
  removePartnerWorker (organization, partnerWorkerId) {
    const url = 'admin/organizations/' + organization.id + '/partner-workers/' + partnerWorkerId;
    return https.delete(url)
  },
  createPartnerWorker (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partner-workers'
    return https.post(url, params)
  },
  getPartnerList (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partners/list'

    return https.get(url, { params })
  },
  uploadPartnerWorker(organization, formData) {
    const url = `admin/organizations/${organization.id}/partner-workers/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  verifyPartnerWorkerApproved(organization, params) {
    const url = 'admin/organizations/' + organization.id + '/partner-workers/approved';
    return https.post(url, params)
  },
  verifyPartnerWorkerRejected(organization, params) {
    const url = 'admin/organizations/' + organization.id + '/partner-workers/rejected';
    console.log(params)
    return https.post(url, params)
  },
  exportPartnerWorker (organization, params) {
    const url = `admin/organizations/${organization.id}/partner-workers/export`
    return https.get(url, { params })
  },
}

export default partnerWorkerApi
